<template>
    <div class="TableCard">
        <el-card class="box-card">
            <template #header v-if="header !== null">
                <div class="card-header">
                    <span>{{ header }}</span>
                </div>
            </template>
            <div class="text item">
                <el-table :data="tableData">
                    <el-table-column v-for="option in tableOptions" :key="option" :prop="option.prop" :label="option.label" />
                </el-table>
            </div>
        </el-card>
        
    </div>
</template>

<script>
// @ is an alias to /src

export default 
{
    name: 'TableCard',
    props: {
        header: {
            type: String,
            required: false,
            default: null
        },
        tableOptions: {
            type: Array,
            required: false,
            default: function() { return []; }
        },
        tableData: {
            type: Array,
            required: false,
            default: function() { return []; }
        }
    }
}
</script>

<style scoped>

</style>
