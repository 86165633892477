<template>
    <div class="Customers">
        <el-main>
            <el-row>
                <el-button style="float: right;" @click="createUserDialogVisible = true" type="success">Opret ny kunde</el-button>
            </el-row>
            <el-row style="margin-top: 20px">
                <el-table :data="customers" style="width: 100%;">
                    <el-table-column prop="id" label="kunde nr." />
                    <el-table-column prop="fullName" label="Kundenavn" />
                    <el-table-column prop="companyName" label="Viksomhed" />
                    <el-table-column prop="CVR" label="CVR" />
                    <el-table-column prop="email" label="Email" />
                    <el-table-column align="right">
                    <template #header>
                        <el-input v-model="search" @keyup.enter="handleFilter()" size="small" placeholder="Søg i tabel" />
                    </template>
                    <template #default="scope">
                        <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
                        >Se/Ret</el-button
                        >
                    </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-main>
       <el-dialog 
    title="Opret ny bruger" 
    v-model="createUserDialogVisible" 
    width="400px"
    destroy-on-close
    >
        <el-form :model="loginForm" id="createUserForm">
            <el-form-item>
                <el-input type="text" v-model="createUserForm.name" placeholder="Fulde navn">
                    <!-- <template #prepend>Fulde navn</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showNameError">Navn ugyldig</span>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="createUserForm.asPrivate" /> Opret bruger som privatperson
            </el-form-item>
            <el-form-item v-if="!createUserForm.asPrivate">
                <el-input type="text" v-model="createUserForm.companyName" placeholder="Virksomhedens navn">
                    <!-- <template #prepend>Virksomhedsnavn</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showCompanyNameError">Virksomhedens navn ugyldig</span>
            </el-form-item>
            <el-form-item v-if="!createUserForm.asPrivate">
                <el-input type="number" v-model="createUserForm.cvr" placeholder="CVR nr.">
                    <!-- <template #prepend>CVR</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showCVRError">CVR nr. ugyldig</span>
            </el-form-item>
            <el-form-item>
                
                <el-input type="text" placeholder="Vejnavn og hus nr." id="dawa-autocomplete-input" v-model="createUserForm.streetnameAndNumber">
                    <!-- <template #prepend>Vejnavn og hus nr.</template> -->
                </el-input>
                <div class="autocomplete-container">
                </div>
                <span class="input-error" v-if="createUserFormValidation.showStreetnameAndNumberError">Feltet skal udfyldes</span> 
            </el-form-item>
            <el-form-item>
                <el-input type="number" placeholder="Postnummer" id="zipCode" v-model="createUserForm.zipCode">
                    <!-- <template #prepend>Postnummer</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showZipCodeError">Feltet skal udfyldes og indeholde 4 tegn</span>
            </el-form-item>
            <el-form-item>
                <el-input type="text" placeholder="By" id="city" v-model="createUserForm.city">
                    <!-- <template #prepend>By</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showCityError">Feltet skal udfyldes</span>
            </el-form-item>
            <el-form-item>
                <el-input type="email" v-model="createUserForm.emailAddress" placeholder="Email">
                    <!-- <template #prepend>Email</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showEmailAddressError">Email ugyldig</span>
            </el-form-item>
            <div style="padding-bottom: 20px;">
                <el-checkbox v-model="createUserForm.useBillingEmail" /> Brug en anden email til modtagelse af faktura.
            </div>
            <el-form-item v-if="createUserForm.useBillingEmail">
                <el-input type="email" v-model="createUserForm.billingEmail" placeholder="Faktura email">
                    <!-- <template #prepend>Fakutra email</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showBillingAddressError">Faktura email ugyldig</span>
            </el-form-item>
            <el-form-item>
                <el-input type="number" v-model="createUserForm.phone" placeholder="Telefon nr.">
                    <!-- <template #prepend>Telefon nr.</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showPhoneError">Telefon nr. ugyldig</span>
            </el-form-item>
            <el-form-item>
                <el-input type="password" v-model="createUserForm.password" placeholder="Adgangskode" show-password>
                    <!-- <template #prepend>Adgangskode</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showPasswordError && !createUserFormValidation.showPasswordMisMatchError">Adgangskode ugyldig</span>
                <span class="input-error" v-if="createUserFormValidation.showPasswordMisMatchError">Adgangskode matcher ikke</span>
                <span class="input-info">Adgangskode skal indeholde mindst 8 tegn og tal</span>
            </el-form-item>
             <el-form-item>
                <el-input type="password" v-model="createUserForm.confirmPassword" placeholder="Adgangskode igen" show-password>
                    <!-- <template #prepend>adgangskode igen</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showPasswordConfirmError">Adgangskode igen ugyldigt</span>
            </el-form-item>
            <el-form-item>
                <el-button :loading="createUserForm.loading" class="login-button" @click="createUser">Opret bruger</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
export default 
{
    name: 'Customers',
    data() {
        return {
            customers: [],
            createUserDialogVisible: false,
            createUserForm: {
              name: '',
              companyName: '',
              cvr: '',
              emailAddress: '',
              billingEmail: '',
              phone: '',
              password: '',
              confirmPassword: '',
              useBillingEmail: false,
              asPrivate: false,
              conditionsAccepted: false,
              signupForNewsletter: false,
              loading: false,
              streetnameAndNumber: '',
              zipCode: '',
              city: '',
            },
            createUserFormValidation: {
              showNameError: false,
              showCompanyNameError: false,
              showCVRError: false,
              showEmailAddressError: false,
              showBillingAddressError: false,
              showPhoneError: false,
              showPasswordError: false,
              showPasswordConfirmError: false,
              showPasswordMisMatchError: false,
              shopwConditionsAcceptedError: false,
              showStreetnameAndNumberError: false,
              showZipCodeError: false,
              showCityError: false
          },
        }
    },
    mounted() {
        this.PopulateCustomers();
    },
    methods: {
        PopulateCustomers() {
            axios.get(`${process.env.VUE_APP_BACKEND_URL}Admin/GetCustomers`)
            .then((response) => {
                console.log("GetCustomers: ", response)
            });

          var customerList = [];
          
          for(var i = 0; i < 4; i++) 
          {
              customerList.push({
                  id: i,
                  fullName: "Steffen Nielsen " + i,
                  companyName: "Virksomhedsnavn " + i,
                  CVR: 1234123 + i,
                  email: "steffennielsen95@gmail.com"
              });
          }

          this.customers = customerList;
          
        },
         handleEdit(index, row)
        {
            console.log("handleEdit: ", index, row);
        },
        handleFilter()
        {
            console.log("HandleFilter");
        },
        closeCreateDialog() {
          this.createUserDialogVisible = false;
      },
      resetCreateUserFormValidation() {
          this.createUserFormValidation.showNameError = false;
          this.createUserFormValidation.showCompanyNameError = false;
          this.createUserFormValidation.showCVRError = false;
          this.createUserFormValidation.showEmailAddressError = false;
          this.createUserFormValidation.showBillingAddressError = false;
          this.createUserFormValidation.showPasswordError = false;
          this.createUserFormValidation.showPasswordConfirmError = false;
          this.createUserFormValidation.showPasswordMisMatchError = false;
          this.resetCreateUserFormValidation.shopwConditionsAcceptedError = false;
          this.createUserFormValidation.showStreetnameAndNumberError = false;
          this.createUserFormValidation.showZipCodeError = false;
          this.createUserFormValidation.showCityError = false;
      },
      validateCreateUserForm() {
          var validationFailed = false;

          if(this.createUserForm.name.length === 0)
          {
              this.createUserFormValidation.showNameError = true;
              validationFailed = true;
          }
          if(this.createUserForm.companyName.length === 0 && !this.createUserForm.asPrivate)
          {
              this.createUserFormValidation.showCompanyNameError = true;
              validationFailed = true;
          }
          if(this.createUserForm.cvr.length !== 8 && !this.createUserForm.asPrivate)
          {
              this.createUserFormValidation.showCVRError = true;
              validationFailed = true;
          }
          if(this.createUserForm.streetnameAndNumber.length < 1)
          {
              this.createUserFormValidation.showStreetnameAndNumberError = true;
          }
          if(this.createUserForm.zipCode.length !== 4)
          {
              this.createUserFormValidation.showZipCodeError = true;
          }
          if(this.createUserForm.city.length < 1) 
          {
              this.createUserFormValidation.showCityError = true;
          }
          if(this.createUserForm.emailAddress.length === 0 || !this.validateEmail(this.createUserForm.emailAddress))
          {
              this.createUserFormValidation.showEmailAddressError = true;
              validationFailed = true;
          }
          if(this.createUserForm.useBillingEmail === true && (this.createUserForm.billingEmail.length === 0 || !this.validateEmail(this.createUserForm.billingEmail)))
          {
              this.createUserFormValidation.showBillingAddressError = true;
              validationFailed = true;
          }
          if(this.createUserForm.phone.length !== 8)
          {
              this.createUserFormValidation.showPhoneError = true;
              validationFailed = true;
          }
          if(this.createUserForm.password.length < 8 || !this.validatePassword(this.createUserForm.password))
          {
              this.createUserFormValidation.showPasswordError = true;
              validationFailed = true;
          }
          if(this.createUserForm.confirmPassword.length < 8 || !this.validatePassword(this.createUserForm.confirmPassword))
          {
              this.createUserFormValidation.showPasswordConfirmError = true;
              validationFailed = true;
          }
          else if(this.createUserForm.password !== this.createUserForm.confirmPassword)
          {
              this.createUserFormValidation.showPasswordMisMatchError = true;
              validationFailed = true;
          }

          return validationFailed;
          
      },
      validateEmail(email) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
      },
      validatePassword(password) {
        const re = /\d/;
        return re.test(password);
      },
      createUser() {
          this.resetCreateUserFormValidation();
          var validationResult = this.validateCreateUserForm();

          var emailForBilling = this.createUserForm.emailAddress;

          if(this.createUserForm.emailForBilling != undefined && this.createUserForm.emailForBilling.length > 0)
          {
              emailForBilling = this.createUserForm.emailForBilling;
          }

          if(!validationResult) {
            this.createUserForm.loading = true;

            if(this.createUserForm.cvr === '')
                this.createUserForm.cvr = '00000000'; 

            if(this.createUserForm.companyName === '') 
                this.createUserForm.companyName = 'PRIVAT PERSON'
            
            axios.post(`${process.env.VUE_APP_BACKEND_URL}Auth/Register`, {
                "fullName": this.createUserForm.name,
                "sendEmail": false,
                "email": this.createUserForm.emailAddress,
                "useBillingEmail": this.createUserForm.useBillingEmail,
                "emailForBilling": emailForBilling,
                "cvr": parseInt(this.createUserForm.cvr),
                "phoneNumber": parseInt(this.createUserForm.phone),
                "password": this.createUserForm.password,
                "confirmPassword": this.createUserForm.confirmPassword,
                "acceptedConditions:": this.createUserForm.conditionsAccepted,
                "receiveNewsletters": this.createUserForm.receiveNewsletters,
                "companyName": this.createUserForm.companyName,
                "streetNameAndNumber": this.createUserForm.streetnameAndNumber,
                "zipCode": this.createUserForm.zipCode,
                "city": this.createUserForm.city
            })
            .then(response => {
                if(response.isSuccess)
                {
                    this.closeCreateDialog();
                }
            }).catch(error => {
                console.log("Register error response: ", error.response.data);
            });
          }
      },
    }
}
</script>

<style scoped>

</style>
