<template>
    <div class="DisountCodes">
        <el-main>
            <el-row gutter="20">
                <el-col>
                    <el-button type="success">Opret ny rabatkode</el-button>
                </el-col>
            </el-row>
            <el-row gutter="20" style="margin-top: 20px;">
                <el-col :span="24">
                    <el-table :data="discountCodes" style="width: 100%;">
                        <el-table-column prop="ID" label="ID" />
                        <el-table-column prop="Name" label="Navn" />
                        <el-table-column prop="Code" label="Rabatkode" />
                        <el-table-column prop="Active" label="Aktiv" />
                        <el-table-column prop="UsedCount" label="Gange anvendt" />
                        <el-table-column align="right">
                             <template #default="scope">
                                <el-button size="small" @click="console.log('edit')">Ret</el-button>
                                <el-button
                                size="small"
                                type="danger"
                                @click="console.log(scope.$index, scope.row)">Slet</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-main>
    </div>
</template>

<script>
// @ is an alias to /src

export default 
{
    name: 'DiscountCodes',
    components: {
        
    },
    data() {
        return {
            discountCodes: [
                {
                    ID: 1,
                    Name: "Test rabatkode 1234",
                    Code: 'Test1234',
                    Active: true,
                    UsedCount: 10
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
