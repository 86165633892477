<template>
    <div class="bookingEdit">
        <el-tabs :tab-position="left">
            <el-tab-pane label="Generelt">
                <el-form v-if="bookingData.customerData != null" :inline="true" :model="bookingData">
                    <div clss="row">
                        <el-row>
                            <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                                <h2>Kunde (booking nr. {{ bookingData.orderNumber}})</h2>
                            </el-col>
                        </el-row>

                        <el-row v-if="bookingData.customerData.id !== ''" style="margin-top: 20px;">
                            <el-col :lg="7">
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Kunde nr.:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.id }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Navn:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.companyName }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">CVR:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.cvr }}</el-col>
                                </el-row>
                            </el-col>
                            <el-col :lg="7">
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Telefon:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.phoneNumber }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Kontaktperson:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.fullName }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">E-mail:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.email }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Faktura e-mail:</el-col>
                                    <el-col :lg="18">{{ bookingData.customerData.emailForBilling }}</el-col>
                                </el-row>
                            </el-col>
                            <el-col :lg="10">
                                <el-row>
                                    <el-col :lg="24">
                                        <el-form-item label="Fakture email modtager">
                                            <el-input disabled type="text" v-model="bookingData.customerData.emailToUse" style="width: 300px"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                                <h2>Betaling og oplysninger</h2>
                            </el-col>
                            <el-col :lg="24" v-if="bookingData.isPaid" style="margin-top: 20px; font-size: 18px;">Betalingsstatus: <span style="color: green;">Betalt</span></el-col>
                            <el-col :lg="3" v-if="!bookingData.isPaid && !bookingData.isOrderedByInvoice" style="margin-top: 20px; font-size: 18px;">Betalingsstatus: <span style="color: red;">Ikke betalt</span></el-col>
                            <el-col :lg="3" v-if="!bookingData.isPaid && bookingData.isOrderedByInvoice" style="margin-top: 20px; font-size: 18px;">Betalingsstatus: <span style="color: red;">Ikke betalt (booking via faktura)</span></el-col>
                            <el-col :lg="9" v-if="!bookingData.isPaid" style="margin-top: 20px; font-size: 18px;"><el-button @click="setBookingAsPaid()">Angiv som betalt</el-button></el-col>
                            <el-col :lg="24" v-if="!bookingData.isPaid" style="margin-top: 20px;">
                                <b>Betalingslink til kunden kan oprettes via følgende link: <a href="https://manage.onpay.io/3022010143675799/payment/create" target="_blank">Onpay.io</a>. Linket skal herefter sendes til kunden, hvor de derefter kan betale faktura.</b>
                            </el-col> 
                            <el-col :lg="24" v-if="!bookingData.isPaid">
                                <b>Husk at indtast "Total pris i DKK", som det fulde beløb og indsæt faktura/ordre nr. {{ bookingData.invoiceNumber }}, i feltet "Ordre ID".</b>    
                            </el-col> 
                            <el-col>
                                <b>Uger: {{ handleData.bookingStartDates }}</b>
                            </el-col>
                        </el-row>


                        <el-row>
                            <el-col :lg="4" style="border-bottom: 1px solid #b4b4b4;">
                                <h2>Fakturaoplysninger (nr. {{ bookingData.invoiceNumber }})</h2>
                            </el-col>
                            <el-col :lg="20" style="margin-top: 15px; border-bottom: 1px solid #b4b4b4;"><el-button @click="downloadInvoice()">Download faktura nr. {{ bookingData.invoiceNumber }}</el-button></el-col>
                            <el-col :lg="24">
                                <invoice-line-table v-if="bookingData.invoiceLines.length > 0" :lines="bookingData.invoiceLines"></invoice-line-table>
                            </el-col>
                        </el-row>
                        <br />      

                        <el-row>
                            <el-col :lg="6">
                                <b>Moms (25%):</b> {{ bookingData.totalVAT }},-
                            </el-col>
                            <el-col :lg="6">
                                <b>Pris i DKK (eksl. moms):</b> {{ bookingData.priceWithoutVAT }},-
                            </el-col>
                            <el-col :lg="6">
                                <b>Total pris i DKK:</b> {{ bookingData.totalPrice }},-
                            </el-col>
                            <el-col :lg="6">
                                <b>Fortjeneste (25%):</b> {{ bookingData.profit }},-
                            </el-col>
                        </el-row>

                    </div>
                </el-form>
            </el-tab-pane>

            <el-tab-pane label="Håndtering">
                <el-row>
                    <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Opstartsdato</h2>
                    </el-col>
                    <el-col  style="margin-top: 20px; font-size: 18px;" :lg="4"> <b>d. {{ startDate }}</b></el-col>
                    <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Nuværende status</h2>
                    </el-col>
                    <el-col  style="margin-top: 20px; font-size: 18px;" :lg="4">Status: <b>{{ handleData.currentStateName }}</b></el-col>
                    <el-col>{{ handleData.currentStateDescription }}</el-col>
                </el-row>
                <el-row>
                    <el-col :lg="12" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Medie (grafik som kunden ønsker vist)</h2>
                    </el-col>
                    <el-col :lg="12" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Godkend/Afvis</h2>
                    </el-col>
                    <el-col :lg="12">
                        <el-main>
                        <el-button v-if="bookingData.image !== null" @click="downloadFile(bookingData.image.base64, bookingData.fileMimeType, bookingData.image.name)">Download media</el-button> <br /><br />
                        <el-card class="box-card" style="text-align: center;">
                            <el-empty v-if="bookingData.image === null" description="Mangler medie" />
                            <el-image v-if="bookingData.image !== null" :src="'data:image/jpeg;base64,'+ bookingData.image.base64"></el-image>
                        </el-card>
                        </el-main>
                    </el-col>
                    <el-col :lg="12">
                        <el-row style="text-align: center" v-if="handleData.canBeApprovedOrRejected">
                            <el-col :lg="24">
                                <el-button v-if="!handleData.showRejectionMessageInput" @click="acceptBooking()" style="margin-top: 20px;" type="success">Godkend medie</el-button>
                            </el-col>
                            <el-col v-if="handleData.showRejectionMessageInput" :lg="24">
                                <el-input v-model="handleData.rejectionMessage" style="margin-top: 20px;" placeholder="Beskriv her med en fyldengørende tekst, hvorfor dette medie er blevet afvist." type="textarea"></el-input>
                            </el-col>
                            <el-col :lg="24">
                                <el-button v-if="!handleData.showRejectionMessageInput" @Click="handleData.showRejectionMessageInput = true" style="margin-top: 20px;" type="danger">Afvis medie</el-button>
                            </el-col>
                            <el-col :lg="24">
                                <el-button v-if="handleData.showRejectionMessageInput" @Click="handleData.showRejectionMessageInput = false" style="margin-top: 20px;" type="info"><b>Annuller</b></el-button> 
                                <el-button v-if="handleData.showRejectionMessageInput" @click="rejectBooking()" style="margin-top: 20px;" type="danger"><b>Afvis!</b></el-button>
                            </el-col>
                        </el-row>
                        <el-row style="text-align: center" v-if="!handleData.canBeApprovedOrRejected">
                            <el-col :lg="24">Du kan ikke godkende eller afvise med den nuværende status.</el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import InvoiceLineTable from '../Components/InvoiceLineTable.vue';
import axios from 'axios';

export default 
{
    name: 'BookingEdit',
    components: {
        InvoiceLineTable
    },
    props: {
      BookingID: Number
    },
    data() {
        return {
            bookingData: {
                customerData: null,
                isPaid: false,
                isOrderedByInvoice: false,
                datysToGoLive: 0,
                invoiceLines: [],
                totalVAT:  123,
                totalPrice: 213,
                profit: 50,
                orderNumber: 0,
                invoiceNumber: 0,
                priceWithoutVAT: 0,
                image: null,
                showImage: false,
                fileMimeType: null
            },
            handleData: {
                currentStateName: "",
                currentStateDescription: "",
                rejectionMessage: "",
                showRejectionMessageInput: false,
                imageUrl: "",
                canBeApprovedOrRejected: false,
                rejectionDate: "",
                approvedDate: "",
                bookingStartDates: ""
            }
        }
    },
    mounted() {
        this.GetBookingData();
    },
    computed: {
        startDate() {
            var date = new Date();
            date.setDate(date.getDate() + this.bookingData.datysToGoLive);
            return  ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear();
        }
    },
    methods: {
        GetBookingData() {
             axios.get(`${process.env.VUE_APP_BACKEND_URL}Admin/GetBookingInfo?bookingID=${this.BookingID}`)
          .then(response => {
              var data = response.data;

              if(data.success === true) {
                
                data = data.data;
                this.bookingData.customerData = {
                    id: data.customerData.id,
                    companyName: data.customerData.companyName,
                    cvr: data.customerData.cvr,
                    phoneNumber: data.customerData.phoneNumber,
                    fullName: data.customerData.fullName,
                    email: data.customerData.email,
                    emailForBilling: data.customerData.emailForBilling,
                    emailToUse: data.invoiceData.receiverEmail,
                }

                if(data.imageData !== null) {
                    this.bookingData.image = data.imageData;
                    this.getMimeType(data.imageData.base64, data.imageData.imageType);
                }

                this.bookingData.isPaid = data.invoiceData.isPaid;
                this.bookingData.isOrderedByInvoice = data.invoiceData.orderedUsingInvoice,
                this.bookingData.totalVAT = data.invoiceData.vat;
                this.bookingData.priceWithoutVAT = data.invoiceData.priceWithoutVAT;
                this.bookingData.totalPrice = data.invoiceData.totalPrice;
                this.bookingData.profit = data.invoiceData.commission; 
                this.bookingData.orderNumber = data.id;
                this.bookingData.invoiceNumber = data.invoiceData.id;
                this.bookingData.datysToGoLive = data.daysToGoLive;
                this.handleData.imageUrl = data.imageUrl;
                this.handleData.rejectionDate = data.hasBeenRejectedDate;
                this.handleData.approvedDate = data.hasBeenApprovedDate;
                this.handleData.bookingStartDates = data.bookingStartDates;

                if(this.bookingData.image !== null)
                {
                    this.bookingData.showImage = true;
                }
                
                this.setState(data);
                this.GenerateInvoiceLines(data.invoiceData.invoiceLines);
              }
          }).catch(error => {
                console.log(error);
          })
        },
        setState(bookingData) {
            if(bookingData.hasEnded) {
                this.setCurrentStateNameAndDescription("Afsluttet", "Annonceringen er afsluttet på skærmen for den bookede periode.");
            } else if (bookingData.hasBeenApproved) {
                this.handleData.canBeApprovedOrRejected = true;
                this.setCurrentStateNameAndDescription(`Godkendt d. ${this.handleData.approvedDate}`, "Mediet er godkendt og vi afventer nu blot startdato for bookingen");
            } else if (bookingData.hasBeenRejected) {
                this.handleData.canBeApprovedOrRejected = true;
                this.setCurrentStateNameAndDescription(`Afvist d. ${this.handleData.rejectionDate}` , `Vi har afvist mediet med følgende begrundelse: ${bookingData.rejectionReason}`);
            } else if (bookingData.sentToReview) {
                this.handleData.canBeApprovedOrRejected = true;
                this.setCurrentStateNameAndDescription("Afventer gennemgang", "Kunden har uploadet medie til skærmen og afventer nu at mediet bliver gennemgået.");
            } else if(bookingData.missingMedia) {
                this.handleData.canBeApprovedOrRejected = true;
                this.setCurrentStateNameAndDescription("Medie mangler", "Kunden har endnu ikke uploadet noget medie til skærmen.");
            } else {
                this.setCurrentStateNameAndDescription("Kunne ikke bestemme status", "");
            }
        },
        setCurrentStateNameAndDescription(stateName, description) {
            this.handleData.currentStateName = stateName;
            this.handleData.currentStateDescription = description;
        },
        GenerateInvoiceLines(invoiceLines) {
            var lines = [];
            for(var i = 0; i < invoiceLines.length; i++)
            {
                lines.push({
                    title: invoiceLines[i].description,
                    address: invoiceLines[i].description,
                    pricePrWeek: invoiceLines[i].spotOriginalPricePerWeek,
                    commissionInPercent: invoiceLines[i].comissionInPercent,
                    weekCount: invoiceLines[i].weeks,
                    totalPrice: invoiceLines[i].priceWithoutVAT
                });
            }
            this.bookingData.invoiceLines = lines;
        },
        rejectBooking() {
            var request = {
                BookingID: this.BookingID,
                RejectionReason: this.handleData.rejectionMessage
            };

            axios.post(`${process.env.VUE_APP_BACKEND_URL}Admin/RejectBooking`, request)
            .then(response => {
              console.log("response from rejection: ", response);
            }).catch(error => {
                console.log(error);
            })
        },
        acceptBooking() {
            axios.post(`${process.env.VUE_APP_BACKEND_URL}Admin/AcceptBooking?BookingID=${this.BookingID}`)
            .then(response => {
                console.log("response from accept: ", response);
            })
            .catch(error => {
                console.log("error accepting booking: ", error);
            });
        },
        setBookingAsPaid() {
            axios.post(`${process.env.VUE_APP_BACKEND_URL}Admin/SetInvoiceAsPaid?InvoiceID=${this.bookingData.invoiceNumber}`)
            .then(response => {
                console.log("response from SetInvoiceAsPaid: ", response);
                this.bookingData.isPaid = true;
            })
            .catch(error => {
                console.log("error accepting booking: ", error);
            });
        },
        downloadInvoice() {
            axios({
                url: `${process.env.VUE_APP_BACKEND_URL}Invoice/DownloadInvoice?invoiceID=${this.bookingData.invoiceNumber}`,
                method: 'GET',
                responseType: 'blob'
                }).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', `Faktura ordre nr ${this.bookingData.invoiceNumber}.pdf`);
                document.body.appendChild(fileLink);
            
                fileLink.click();

                for(var index = 0; index < this.invoices.length; index++)
                {
                this.invoices[index].loading = false;
                }
            });
        },
        getMimeType(base64String, fileType) {
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: `image/${fileType}` }); // You can change 'image' to 'video' for video files

            const reader = new FileReader();
            reader.onloadend = () => {
                const arr = new Uint8Array(reader.result).subarray(0, 4);
                let header = "";
                for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
                }

                switch (header) {
                case "89504e47":
                    this.bookingData.fileMimeType = "image/png";
                    break;
                case "47494638":
                    this.bookingData.fileMimeType = "image/gif";
                    break;
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                    this.bookingData.fileMimeType = "image/jpeg";
                    break;
                case "49443303":
                    this.bookingData.fileMimeType = "audio/mp3";
                    break;
                case "00000018":
                case "0000001c":
                case "00020":
                    this.bookingData.fileMimeType = "video/mp4";
                    break;
                default:
                    this.bookingData.fileMimeType = null; // Couldn't determine the mimetype
                }
            };

            reader.readAsArrayBuffer(blob);
        },
        downloadFile(base64String, mimeType, fileName) {
            const blob = this.base64ToBlob(base64String, mimeType);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        },
        base64ToBlob(base64String, mimeType) {
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], { type: mimeType });
        },
        

    }
}
</script>

<style scoped>

</style>
