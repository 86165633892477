<template>
    <div class="Bookings">
        <el-main>
            <el-row>
                <el-button style="float: left;" @click="dialogVisible = true" type="success">Opret booking</el-button>
                <el-button style="float: left;" type="primary" @click="ResetStatusFilter()">Alle bookings ({{originalData.length}})</el-button>
                <el-button style="float: left;" type="primary" v-for="(item) in statuses" :key="item.Status" @click="FilterByStatus(item.Status)">{{item.Status}} ({{item.items.length}})</el-button>
            </el-row>
            <booking-table v-if="tableData.length > 0" :Data="tableData" Title="Bookings" :key="tableData.length" />
        </el-main>
    </div>

    <el-dialog
        v-model="dialogVisible"
        title="Opret booking"
        width="80%"
    >
        <el-form :inline="true" :model="createBookingData">
            <div class="row">
                <el-row>
                    <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Kunde</h2>
                    </el-col>
                    <el-col :lg="24">
                        <el-form-item style="margin-top: 20px;">
                            <el-autocomplete
                                v-model="createBookingData.selectedCustomerString"
                                :fetch-suggestions="searchForCustomer"
                                :trigger-on-focus="false"
                                class="inline-input"
                                placeholder="Søg efter kunde"
                                @select="handleSelectCustomer"
                                clearable
                            >
                            <template #default="{ item }">
                                <div class="name">{{ item.id }} : {{ item.fullName }}</div>
                            </template>
                            </el-autocomplete>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="createBookingData.customerID !== ''">
                    <el-col :lg="7">
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Kunde nr.:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.id }}</el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Navn:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.companyName }}</el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">CVR:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.cvr }}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :lg="7">
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Telefon:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.phoneNumber }}</el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Kontaktperson:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.fullName }}</el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">E-mail:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.email }}</el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="6" style="paddin-right: 20px; font-weight: bold;">Faktura e-mail:</el-col>
                            <el-col :lg="18">{{ createBookingData.customerData.emailForBilling }}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :lg="10">
                        <el-row>
                            <el-col :lg="24">
                                <el-form-item label="Faktura e-mail">
                                    <el-input type="text" v-model="createBookingData.customerData.emailToUse" style="width: 300px"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Betaling og oplysninger</h2>
                    </el-col>
                    <br />
                    <el-col :lg="6" style="margin-top: 20px;">
                        <el-form-item>
                            <el-checkbox v-model="createBookingData.isPaid">
                            Er beløb betalt på forhånd?
                            </el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="18" style="margin-top: 25px;"><b>Efter booking og faktura er oprettet, vil du på denne booking kunne oprette et betalingslink.</b></el-col>
                    <!-- <el-col :lg="12" style="margin-top: 20px;">
                        <el-form-item label="Betalingslink (link vil fremgå i email)">
                            <el-input type="text" :disabled="createBookingData.isPaid" v-model="createBookingData.paymentLink" style="width: 400px" placeholder="Betalingslink fra onpay" />
                        </el-form-item>
                    </el-col> -->
                </el-row>

                <el-row>
                    <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Skærme</h2>
                    </el-col>
                    <el-col :lg="24">
                        <el-form-item style="margin-top: 20px;">
                            <el-autocomplete
                                v-model="createBookingData.selectedSpotString"
                                :fetch-suggestions="querySpots"
                                :trigger-on-focus="false"
                                class="inline-input"
                                placeholder="Søg og tilføj skærm"
                                @select="handleSelectSpot"
                            >
                            <template #default="{ item }">
                                <div class="name">{{ item.id }} : {{ item.attributes.Title }} - {{ item.attributes.Address }}, {{ item.attributes.City }}</div>
                            </template>
                            </el-autocomplete>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <create-booking-screen-table ref="screenTable" :Data="createBookingData.spots"></create-booking-screen-table>
            <div class="row">
                <el-row>
                    <el-col :lg="24" style="border-bottom: 1px solid #b4b4b4;">
                        <h2>Fakturaoplysninger</h2>
                    </el-col>
                </el-row>
                <br />
                <el-row>
                    <el-col :lg="6">
                        <b>Moms (25%):</b> {{ totalVAT }},-
                    </el-col>
                    <el-col :lg="6">
                        <b>Pris i DKK (eksl. moms):</b> {{ totalPrice }},-
                    </el-col>
                    <el-col :lg="6">
                        <b>Total pris i DKK:</b> {{ totalPrice + totalVAT }},-
                    </el-col>
                    <el-col :lg="6">
                        <b>Fortjeneste (25%):</b> {{ profit }},-
                    </el-col>
                </el-row>
            </div>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
            <el-checkbox style="padding-right: 20px;" v-model="createBookingData.dontSendEmail">Send ikke email til kunden (kunden vil ikke modtage faktura eller anden email)</el-checkbox>
            <el-button @click="resetCreateBookingData()">Annuller</el-button>
            <el-button type="primary" :disabled="createBookingData.customerID < 1 || createBookingData.spots.length < 1" @click="createBooking(createBookingData)">Opret og send booking</el-button>
        </span>
        </template>
    </el-dialog>
    
</template>

<script>
import BookingTable from '../AdminPage/Components/BookingTable.vue';
import CreateBookingScreenTable from '../AdminPage/Components/CreateBookingScreenTable.vue';
import axios from 'axios';
import qs from 'qs';
// @ is an alias to /src

export default 
{
    components: { BookingTable, CreateBookingScreenTable },
    name: 'BookingOverview',
    data() {
        return {
            awaitingBookings: [],
            activeBookings: [],
            tableData: [],
            originalData: [],
            statuses: [],
            dialogVisible: false,
            createBookingData: {
                customerID: '',
                customerData: null,
                selectedCustomerString: "",
                selectedSpotString: "",
                spots: [],
                isPaid: false,
                paymentLink: "",
                invoiceEmail: "",
                dontSendEmail: false,
            },
            customersData: [],
            spotsData: []
        }
    },
    beforeMount() {
        this.GetBookings();
        this.getCustomers();
        this.getSpots();
    },
    computed: {
        totalVAT: function() {
            var totalPriceWithoutVAT = this.totalPrice;

            if(totalPriceWithoutVAT !== 0)
                return totalPriceWithoutVAT * 0.25;
            else 
                return 0;
        },
        totalPrice: function() {
            var totalPriceWithoutVAT = 0;
            for(var i = 0; i < this.createBookingData.spots.length; i++)
            {
                totalPriceWithoutVAT += this.createBookingData.spots[i].totalPrice;
            }
            console.log("totalPrice: ", totalPriceWithoutVAT);
            return totalPriceWithoutVAT;
        },
        profit: function() {
            var totalPriceWithoutVAT = this.totalPrice;
            if(totalPriceWithoutVAT > 0) 
                return totalPriceWithoutVAT * 0.25;
            else
                return totalPriceWithoutVAT;
        }
    },
    methods: {
        FilterByStatus(status) {
            this.tableData = this.originalData.slice().filter(booking => booking.Status.toLowerCase() === status.toLowerCase());
        },
        ResetStatusFilter() {
            this.tableData = this.originalData();
        },
        GetBookings() {
            axios.get(`${process.env.VUE_APP_BACKEND_URL}admin/GetBookings?bookingType=0`)
            .then(response => {
                if(response.data.success)
                {
                    var data = [];
                    var bookings = response.data.data;
                    for(var i = 0; i < bookings.length; i++)
                    {
                        data.push({
                            BookingID: bookings[i].bookingID,
                            CustomerName: bookings[i].customerName,
                            StartDate: bookings[i].startDate,
                            Status: bookings[i].status
                        });
                    }

                    this.tableData = data.sort((a, b) => b.BookingID - a.BookingID);
                    this.originalData = data.sort((a, b) => b.BookingID - a.BookingID);

                    if(this.tableData.length > 0) {
                        const groupedStatuses = this.tableData.reduce((acc, item) => {
                            const { Status } = item;
                            // Find the index of an existing group with the same status
                            const index = acc.findIndex(group => group.Status === Status);
                            
                            if (index !== -1) {
                                // If a group with the same status exists, push the item into that group
                                acc[index].items.push(item);
                            } else {
                                // If no group with the same status exists, create a new group
                                acc.push({ Status, items: [item] });
                            }
                            
                            return acc;
                        }, []);
                        this.statuses = groupedStatuses;
                        
                    }
                    else {
                        this.statuses = [];
                    }
                }

                
            });
        },
        resetCreateBookingData() {
            this.dialogVisible = false;
            this.createBookingData = {
                customerID: 0
            }
        },
        createBooking(booking) {
            var DTO = {
                customerID: booking.customerID,
                customerInvoiceEmail: booking.customerData.emailToUse,
                paymentLink: booking.paymentLink,
                isPaid: booking.isPaid,
                lines: [],
                doNotSentEmailToCustomerOnCreation: booking.dontSendEmail
            };

            for(var i = 0; i < booking.spots.length; i++)
            {
                var spot = booking.spots[i];
                var weeks = [];
                for(var x = 0; x < spot.selectedWeeks.length; x++)
                {
                    var monday = spot.selectedWeeks[x];
                    var sunday = new Date(monday);
                    sunday.setDate(sunday.getDate() + 6);
                    sunday.setHours(2);

                    weeks.push({
                        StartDate: monday,
                        EndDate: sunday,
                    })
                }

                DTO.lines.push({
                    spotID: spot.id,
                    bookingDateRanges: weeks,
                    totaldiscountForLine: spot.discount,
                    priceWithoutVAT: spot.totalPrice,
                    city: spot.city,
                    region: spot.region,
                    address: spot.address,
                    imageUrl: spot.imageUrl,
                    spotTitle: spot.title
                })
            }

            axios.post(`${process.env.VUE_APP_BACKEND_URL}Admin/CreateBooking`, DTO)
            .then(response => {
                console.log("Created booking: ", response);
                this.dialogVisible = false;
            }).catch(error => {
                    console.log(error);
            })

            //this.resetCreateBookingData();
        },
        handleCustomerAutocomplete() {
            
        },
        getCustomers() {
            var customers = [];
            
            for(var i = 0; i < 4; i++)
            {
                customers.push({
                    id: i + 1,
                    Name: "Orla " + i 
                })
            }

            this.customersData = customers;
        },
        searchForCustomer(queryString, cb) {
            axios.get(`${process.env.VUE_APP_BACKEND_URL}admin/GetCustomers?search=${queryString}`)
            .then(response => {
                cb(response.data.data);
            });
        },
        handleSelectCustomer(selectedCutomer)
        {
            this.createBookingData.customerID = selectedCutomer.aspNetUserID;
            this.createBookingData.selectedCustomerString = `${selectedCutomer.id} : ${selectedCutomer.fullName}`;
            this.createBookingData.customerData = selectedCutomer;

            if(typeof selectedCutomer.emailForBilling === "undefined")
            {
                this.createBookingData.customerData.emailToUse = selectedCutomer.email;
            }
            else {
                 this.createBookingData.customerData.emailToUse = selectedCutomer.emailForBilling;
            }
        },
        getSpots() {
            var spots = [];
            
            for(var i = 0; i < 4; i++)
            {
                spots.push({
                    id: i + 1,
                    name: "Gug, Nordjylland,Aalborg,Egnsplanvej " + i,
                    address: "Hjortøgade " + i + " 3.TV, 9000 Aalborg",
                    pricePrWeek: 200 + i,
                    comissionInPercent: 25,
                    weekCount: 0,
                    totalPrice: 0,
                    discount: 0,
                    selectedWeeks: []
                })
            }

            this.spotsData = spots;
        },
        querySpots(queryString, cb) {
            var query = qs.stringify({
                populate: ['Images', 'region', 'spot_savings'],
                filters: {
                    Title: {
                    $contains: queryString,
                    },
                },
            });

            axios.get(`${process.env.VUE_APP_STRAPI_API_URL}spots/?${query}`, )
            .then(response => {
                cb(response.data.data);
            })
        },
        handleSelectSpot(selectedSpot) {
            this.$refs.screenTable.addToList(this.convertSelectedSpotToModel(selectedSpot));
        },
        getSelectedSpots() {
            return this.$refs.screenTable.getData();
        },
        convertSelectedSpotToModel(selectedSpot) {
            return {
                id: selectedSpot.id,
                title: selectedSpot.attributes.Title,
                address: selectedSpot.attributes.Address,
                pricePrWeek: selectedSpot.attributes.PricePrWeek,
                commissionInPercent: selectedSpot.attributes.CommissionInPercent,
                weekCount: 0,
                totalPrice: 0,
                discount: 0,
                selectedWeeks: [],
                imageUrl: selectedSpot.attributes.Images.data[0].attributes.formats.medium.url,
                city: selectedSpot.attributes.City,
                region: selectedSpot.attributes.region.data.attributes.Name,
            }
        }
    }
}
</script>

<style scoped>


</style>
