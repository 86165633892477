<template>
    <div class="Finance">
        <el-main>
            <el-row gutter="20">
                <el-col>
                    <el-button type="success">Opret nyt betalingsoversigt</el-button>
                    <el-button type="success">Afstem udbydere</el-button>
                </el-col>
            </el-row>
            <el-row gutter="20" style="margin-top: 20px;">
                <el-col :span="8">
                    <earnings-card 
                        header="Omsætning i dette kvartal" 
                        :valueToShow="quaterValue"
                        unit="DKK"
                        fromDate="01.01-2022"
                        toDate="31.03-2022" /> 
                </el-col>
                <el-col :span="8">
                    <earnings-card 
                        header="Omsætning i denne måned" 
                        :valueToShow="monthValue"
                        unit="DKK"
                        otherPeriode="Juli"
                         /> 
                </el-col>
                <el-col :span="8">
                    <earnings-card 
                        header="Omsætning i år" 
                        :valueToShow="yearValue"
                        unit="DKK"
                        otherPeriode="2022" /> 
                </el-col>
            </el-row>
            <el-divider />
            <el-row gutter="20">
                <el-col :span="12">
                    <table-card 
                        header="Bedste indtjente kunder" 
                        :tableOptions="bestCustomersOptions"
                        :tableData="bestCustomers"/>
                </el-col>
                <el-col :span="12">
                    <table-card 
                        header="Dårligst indjente kunder" 
                        :tableOptions="bestCustomersOptions"
                        :tableData="bestCustomers"/>
                </el-col>
            </el-row>
        </el-main>
    </div>
</template>

<script>
// @ is an alias to /src
import EarningsCard from '../AdminPage/Components/Financial/EarningsCard.vue'
import TableCard from '../AdminPage/Components/Financial/TableCard.vue'
import axios from 'axios'

export default 
{
    name: 'Finance',
    components: {
        EarningsCard,
        TableCard
    },
    data() {
        return {
            monthValue: 0,
            quaterValue: 0,
            yearValue : 0,
            bestCustomersOptions: [
                {
                    prop: "ID",
                    label: "Kunde nr."
                },
                {
                    prop: "Name",
                    label: "Kunde navn"
                },
                {
                    prop: "InvoiceCount",
                    label: "Antal ordre"
                },
                {
                    prop: "Spent",
                    label: "Beløb (DKK)"
                }
            ],
            bestCustomers: [
                {
                    ID: 1,
                    Name: "Test",
                    InvoiceCount: 6,
                    Spent: 8000
                },
                {
                    ID: 2,
                    Name: "Test2",
                    InvoiceCount: 2,
                    Spent: 4500
                }
            ],
        }
    },
    mounted() {
        this.GetFinancialOverview();
    },
    methods: {
        GetFinancialOverview() {
            axios.get(`${process.env.VUE_APP_BACKEND_URL}admin/GetFinancialOverview`)
            .then(response => {
                if(response.data.success)
                {
                    this.monthValue = response.data.data[0].invoiceTotal;
                    this.quaterValue = response.data.data[1].invoiceTotal;
                    this.yearValue = response.data.data[2].invoiceTotal;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
