<template>
    <div class="EarningsCard">
        <el-card class="box-card">
            <template #header v-if="header !== null">
                <div class="card-header">
                    <span>{{ header }}</span>
                </div>
            </template>
            <div class="text item">
                <el-row>
                    <el-col class="valueToShow">{{ valueToShow }}  {{ unit }}</el-col>
                </el-row>
                <el-row>
                    <el-col v-if="fromDate != null && toDate != null">Periode: {{ fromDate }} - {{ toDate }}</el-col>
                    <el-col v-if="otherPeriode != null">{{ otherPeriode }}</el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>
// @ is an alias to /src

export default 
{
    name: 'EarningsCard',
    props: {
        header: {
            type: String,
            required: false,
            default: null
        },
        unit: {
            type: String,
            required: false,
            default: null
        },
        valueToShow: {
            type: String,
            required: true,
            default: null
        },
        fromDate: {
            type: String,
            required: false,
            default: null
        },
        toDate: {
            type: String,
            required: false,
            default: null
        },
        otherPeriode: {
            type: String,
            required: false,
            default: null
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.box-card {
    width: 100%;
    max-width: 480px;
}

.valueToShow {
    text-align: center;
    font-size: 42px;
    padding: 20px 0px 0px 0px;
}
</style>
