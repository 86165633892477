<template>
    <div class="AdminPage">
        <el-main>
            <el-tabs :tab-position="left">
                <el-tab-pane label="Bookings">
                    <booking-overview></booking-overview>
                </el-tab-pane>
                <!-- <el-tab-pane lazy="true" label="Ordreoversigt">
                    <orders></orders>
                </el-tab-pane> -->
                <el-tab-pane lazy="true" label="Kunder">
                    <customers></customers>
                </el-tab-pane>
                <el-tab-pane label="Finans">
                    <finance></finance>
                </el-tab-pane>
                <el-tab-pane label="Rabatkoder">
                    <discount-codes></discount-codes>
                </el-tab-pane>
            </el-tabs>
        </el-main>
    </div>
</template>

<script>
import BookingOverview from "../views/AdminPage/BookingOverview.vue";
// import Orders from "../views/AdminPage/Orders.vue";
import Customers from "../views/AdminPage/Customers.vue";
import Finance from "../views/AdminPage/Finance.vue";
import DiscountCodes from '../views/AdminPage/DiscountCodes.vue';
// @ is an alias to /src

export default 
{
  components: { BookingOverview, Customers, Finance, DiscountCodes },
    name: 'AdminPage',
}
</script>

<style scoped>

</style>
