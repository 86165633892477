<template>
    <div class="BookingTable">
            <h2>{{ Title }}</h2>
            <el-table :data="dataList" style="width: 100%">
                <el-table-column prop="BookingID" label="Booking nr." />
                <el-table-column prop="CustomerName" label="Kundenavn" />
                <el-table-column prop="StartDate" label="Startdato" />
                <el-table-column prop="Status" label="Status" />
                <el-table-column align="right">
                <template #header>
                    <el-input v-model="search" @keyup.enter="handleFilter()" size="small" placeholder="Søg i tabel" />
                </template>
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
                    >Oplysninger</el-button
                    >
                </template>
                </el-table-column>
            </el-table>
    </div>

    <el-dialog
        v-model="editDialogVisible"
        title="Oplysninger om booking"
        width="80%"
        destroy-on-close="true"
    >
        <booking-edit v-if="BookingID > 0" :BookingID="BookingID"></booking-edit>
    </el-dialog>
</template>

<script>
import BookingEdit from '../Components/BookingEdit.vue';
// @ is an alias to /src

export default 
{
    name: 'BookingTable',
    components: {
        BookingEdit
    },
    props: {
      Data: Array,
      Title: String
    },
    data() {
        return {
            originalList: [],
            dataList: [],
            search: '',
            editDialogVisible: false,
            BookingID: 0,
        }
    },
    mounted() {
        this.setOriginData();
    },
    methods: {
        setOriginData() {
            this.originalList = this.Data;
            this.dataList = this.Data;
        },
        handleDelete(index, row)
        {
            console.log("index: ", index, "row: ", row);
        },
        handleEdit(index, row)
        {
            console.log("index: ", index, "row: ", row);
            this.BookingID = row.BookingID;
            this.editDialogVisible = true;
        },
        handleFilter()
        {
            this.dataList = this.originalList.filter(x => 
                { 
                    return x.BookingID === this.search 
                            || x.CustomerName.toLowerCase().includes(this.search.toLowerCase())
                            || x.StartDate.toLowerCase().includes(this.search.toLowerCase())
                            || x.Status.toLowerCase().includes(this.search.toLowerCase())
                });
        }
    }
}
</script>

<style scoped>

</style>
