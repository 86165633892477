<template>
    <div class="BookingTable">
            <el-table :data="dataList" style="width: 100%">
            <el-table-column prop="id" label="Spot nr." />
            <el-table-column prop="title" label="Navn" />
            <el-table-column prop="address" label="Adresse" />
            <el-table-column prop="pricePrWeek" label="Pris pr. uge (eksl. moms)" />
            <el-table-column prop="commissionInPercent" label="Komission i %" />
            <el-table-column prop="weekCount" label="Antal uger" />
            <el-table-column prop="discount" label="Rabat" />
            <el-table-column prop="totalPrice" label="Totalpris (eksl. moms)" />
            <el-table-column align="right">
            <template #header>
                <el-input v-model="search" @keyup.enter="handleFilter()" size="small" placeholder="Søg i tabel" />
            </template>
            <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.$index, scope.row)"
                >Se/Ret</el-button
                >
                <el-button
                size="small"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >Slet</el-button
                >
            </template>
            </el-table-column>
            </el-table>
    </div>

    <el-dialog
        v-model="dialogVisible"
        :title="editDialogTitle"
        width="400px"
    >
        <el-form :inline="true" :model="editModel" style="border-bottom: 1px solid #b4b4b4">
            <el-row :gutter="24">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
                    <el-form-item label="Pris pr. uge (eksl. moms)">
                        <el-input disabled v-model="editModel.pricePrWeek"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Antal uger">
                        <el-input disabled v-model="editModel.weekCount" type="number"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="Rabat i DKK (smalet for linjen)">
                        <el-input @change="onPeriodOrOverridenPriceChange" v-model="editModel.discount" type="currency"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <br />
        <el-form :inline="true" :model="editModel">
            <el-row :gutter="24">
               <el-col :lg="24">
                    <el-form-item label="Vælg uger">
                        <div class="description" v-if="availableWeeks.length > 0">
                            <el-select-v2
                                v-model="editModel.selectedWeeks"
                                :options="availableWeeks"
                                placeholder="Vælg uger"
                                style="width: 250px;"
                                multiple
                                collapse-tags
                                @change="onPeriodOrOverridenPriceChange"
                            />
                        </div>
                    </el-form-item>
               </el-col>
               <el-col :lg="24">
                   <el-form-item label="Total pris (eksl. moms)">
                        <el-input disabled v-model="editModel.totalPrice" type="currency"></el-input>
                    </el-form-item>
               </el-col>
                
            </el-row>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="dialogVisible = false">Luk</el-button>
        </span>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src

// TODO: Liste filtrering, tilføjelse og sletning fungere ikke helt 100% som det burde. Find derfor frem til hvordan dette bedst kan lade sig gøre.

export default 
{
    name: 'CreateBookingScreenTable',
    props: {
      Data: Array,
      Title: String
    },
    data() {
        return {
            originalList: [],
            dataList: [],
            search: '',
            dialogVisible: false,
            editModel: null,
            editDialogTitle: "",
            editModelIndex: 0,
            availableWeeks: []
        }
    },
    mounted() {
        this.setOriginData();
    },
    methods: {
        setOriginData() {
            this.dataList = this.Data;
        },
        handleDelete(index, row)
        {
            this.originalList = this.originalList.filter(x => x.id !== row.id);
            this.dataList = this.originalList;
            
        },
        addToList(item) {
            this.dataList.push(item);
        },
        handleEdit(index, row)
        {
            this.editDialogTitle = "Rediger linje " + row.id;
            this.editModel = row;
            this.editModelIndex = index;
            this.getAvialableDates(row.id);
            this.dialogVisible = true;
        },
        handleFilter()
        {
            if(this.search === "") {
                this.dataList = this.originalList;
            }
            else {
                this.dataList = this.originalList.filter(x => 
                { 
                    return x.id === this.search 
                            || x.name.toLowerCase().includes(this.search.toLowerCase())
                            || x.address.toLowerCase().includes(this.search.toLowerCase())
                            || x.pricePrWeek.toString().toLowerCase().includes(this.search.toLowerCase())
                });
            }
        },
        getData() {
            return this.dataList;
        },
        onPeriodOrOverridenPriceChange()
        {
            if(this.editModel.discount === "" || this.editModel.discount < 0)
                this.editModel.discount = 0;
            this.editModel.weekCount = this.editModel.selectedWeeks.length;
            this.calculateValues();
        },
        calculateValues() {
            this.editModel.totalPrice = (this.editModel.pricePrWeek * this.editModel.weekCount) - this.editModel.discount;
        },
        saveEditModel() {
            this.dialogVisible = false;
            this.editModel = null;
            this.editModelIndex = 0;
        },
        getAvialableDates(spotID) {
          axios.get(`${process.env.VUE_APP_BACKEND_URL}booking/GetAvailableDates?spotID=${spotID}`)
          .then(response => {
              var availableWeeksFromResponse = response.data.data;
              this.availableWeeks = [];
              if(availableWeeksFromResponse !== null) {
                for(var index = 0; index < availableWeeksFromResponse.length; index++)
                {
                    var weekNumber = availableWeeksFromResponse[index].weekNumber;
                    //var monday = new Date(availableWeeksFromResponse[index].mondayDate);
                    var sunday = new Date(availableWeeksFromResponse[index].sundayDate);

                    //var mondayString = monday.getDate() + '-' + (monday.getMonth() + 1) + '/' + monday.getFullYear();
                    //var sundayString = sunday.getDate() + '-' + (sunday.getMonth() + 1) + '/' + sunday.getFullYear();

                    this.availableWeeks.push({
                        value: availableWeeksFromResponse[index].mondayDate,
                        label: `Uge ${weekNumber} | ${sunday.getFullYear()}`
                    })
                }
              }
          });
      }
    }
}
</script>

<style scoped>

</style>
